<template>
  <div class="p-8 gap-5">
    <main class="mx-auto max-w-screen-2xl px-4 py-2 overflow-y-auto mb-32 sm:mb-20">
      <div class="grid gap-6 md:grid-cols-2">

        <div class="p-4 md:p-6 bg-gray-100 rounded-lg">
          <div class="flex flex-wrap h-full gap-4">
            <header class="w-full">
              <p class="text-sm text-gray-600">{{ selectedSpace?.description }}</p>
            </header>
            <main class="w-full mt-auto">
              <dl>
                <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt class="text-sm text-gray-500">
                    Building
                  </dt>
                  <dd class="text-sm font-medium text-gray-800">
                    {{ selectedBuilding?.name }}
                  </dd>
                </div>
                <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt class="text-sm text-gray-500">
                    Address
                  </dt>
                  <dd class="text-sm font-medium text-gray-800">
                    {{ selectedBuilding?.address }}
                  </dd>
                </div>

              </dl>
            </main>
          </div>
        </div>

        <div class="p-4 md:p-6 bg-gray-100 rounded-lg">
          <h4 class="text-md font-semibold text-gray-800 mb-3">Space</h4>
          <dl>
            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Space type
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ category }}
              </dd>
            </div>
            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Subcategory
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.sub_category }}
              </dd>
            </div>
            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Floor area (<span class="">{{ areaUnits }}</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.floor_area }}
              </dd>
            </div>
            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Ceiling height (<span class="">{{ heightUnits }}</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.ceiling_height }}
              </dd>
            </div>
          </dl>

        </div>

        <div class="p-4 md:p-6 bg-gray-100 rounded-lg">
          <h4 class="text-md font-semibold text-gray-800 mb-3">Occupants</h4>
          <dl>
            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Occupancy
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.peak_occupancy }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Occupancy duration (<span class="">min</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.occupant_duration_minutes }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Occupant schedule
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.occupant_schedule }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Occupant profile
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.occupant_profile }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Activity
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.activity }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Mask type
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.mask_type }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Mask use (<span class="">%</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.mask_use_rate }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Social distancing
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.social_distancing ? 'Yes' : 'No' }}
              </dd>
            </div>

            <h4 class="border-t-2 py-2 mt-4 text-md font-semibold text-gray-800">COVID-19 policies</h4>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                COVID-19 vaccine mandate
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.vaccine_mandate ? 'Yes' : 'No' }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                COVID-19 vaccination data
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                —
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                COVID-19 occupant vaccination (<span class="">%</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.background_vaccination_rate }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                COVID-19 boostered vaccine mandate
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.vaccine_mandate_booster ? 'Yes' : 'No' }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                COVID-19 boostered vaccination data
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                —
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                COVID-19 boostered occupant vaccination (<span class="">%</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.background_vaccination_rate_booster }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                COVID-19 testing program <a href="https://help.particleone.com/testing-program-learn-more/" target="_blank"
                  class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg
                    xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.testing_program ? 'Yes' : 'No' }}
              </dd>
            </div>

            <h4 class="border-t-2 py-2 mt-4 text-md font-semibold text-gray-800">Influenza policies</h4>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Influenza vaccine mandate
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.vaccine_mandate_flu ? 'Yes' : 'No' }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Influenza vaccination data
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                —
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Influenza occupant vaccination (<span class="">%</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.background_vaccination_rate_flu ?? 'N/A' }}
              </dd>
            </div>

          </dl>
        </div>

        <div class="p-4 md:p-6 bg-gray-100 rounded-lg">
          <h4 class="text-md font-semibold text-gray-800 mb-3">Building performance</h4>
          <dl>
            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Supply airflow rate (<span class="">{{ airflowUnits }}</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.supply_airflow_rate }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Outside air (<span class="">{{ airflowUnits }}</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.outside_air_rate }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Filtration
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.filtration }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Temperature (<span class="">°{{ temperatureUnits }}</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.temperature }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Humidity (<span class="">%</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.relative_humidity_rate }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Barriers
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.barriers ? 'Yes' : 'No' }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Air-cleaning devices
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.air_cleaning_devices ? 'Yes' : 'No' }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Total CADR (<span class="">{{ airflowUnits }}</span>)
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.total_clean_air_delivery_rate }}
              </dd>
            </div>

            <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt class="text-sm text-gray-500">
                Upper-room UVGI
              </dt>
              <dd class="text-sm font-medium text-gray-800">
                {{ selectedSpace?.space.upper_room_uvgi ? 'Yes' : 'No' }}
              </dd>
            </div>

          </dl>

        </div>

      </div>
    </main>
  </div>
</template>

<script>
import { ParticleOneSpaceCategory, ParticleOneUnit } from '@/network/models/ParticleOneCreateSpaceRequest';
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardSpaceDetails',
  computed: {
    areaUnits() {
      return this.selectedSpace?.space.units === ParticleOneUnit.Imperial ? 'sq. ft.' : 'm²';
    },
    heightUnits() {
      return this.selectedSpace?.space.units === ParticleOneUnit.Imperial ? 'ft.' : 'm';
    },
    temperatureUnits() {
      return this.selectedSpace?.space.units === ParticleOneUnit.Imperial ? 'F' : 'C';
    },
    airflowUnits() {
      return this.selectedSpace?.space.units === ParticleOneUnit.Imperial ? 'CFM' : 'L/s';
    },
    category() {
      return Object
        .keys(ParticleOneSpaceCategory)
        .find(key => ParticleOneSpaceCategory[key] === this.selectedSpace?.space.category);
    },
    ...mapGetters(['selectedBuilding', 'selectedSpace'])
  }
};
</script>
